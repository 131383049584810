<template>
  <div class="bg-grey-light mdmax:bg-white py-12 -mt-10 mdmax:mt-0 min-h-screen mdmax:min-h-full">
    <Modal class="overflow-y-hidden" :modalVisible="isModalRegistration" @close="hideModalRegistration()" id="Registration">
      <template slot="modal-content">
        <div class="p-7">
          <p class="text-center text-xl font-bold mb-2">Upload {{ registrationDocumentTitle }}</p>
          <div class="flex justify-center items-center">
            <div class="w-full py-5 text-center">
              <div :class="`p-5 h-48 bg-white border rounded-lg border-dashed ${fileDocument ? 'border-yellow' : 'border-gray-300'}`" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                <div v-if="fileDocument" class="h-full">
                  <div class="flex justify-end items-center">
                    <div class="cursor-pointer rounded-full hover:opacity-80" @click="remove()">
                      <CrossCircle color="red" height="24" width="24" />
                    </div>
                  </div>
                  <div class="flex flex-col justify-center items-center">
                    <DocumentText color="yellow" height="50" width="50" class="mb-2" />
                    <p class="text-yellow ellipsis">{{ newName.length > 70 ? newName.substr(0, 68) + '..' : newName }}</p>
                  </div>
                </div>
                <div v-else>
                  <div class="flex justify-center mb-5">
                    <img src="@/assets/images/vector.svg" />
                  </div>
                  <input type="file" id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="file" :accept="fileType" />
                  Drag & Drop File
                  <br />or
                  <label for="assetsFieldHandle" class="block">
                    <div class="flex justify-center items-center">
                      <div class="cursor-pointer font-bold rounded-lg border text-xs py-2 px-3 text-white border-yellow bg-yellow hover:border-yellow-300 hover:bg-yellow-300">Select File</div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center items-center my-5">
            <Button :disabled="!fileDocument" class="mr-5" buttonText="Upload" type="secondary" @action="forGetUploadUrl()" />
            <Button buttonText="Cancel" type="tertiary" @action="hideModalRegistration()" />
          </div>
        </div>
      </template>
    </Modal>
    <div class="container mdmax:px-5">
      <div class="breadcrumb flex items-center gap-2 mb-4">
        <router-link to="/" class="text-sm text-yellow mdmax:mr-1 font-medium">Beranda</router-link>
        <Chevron direction="right" width="10" height="10" color="#F7931E" />
        <router-link to="/user/my-classes" class="text-sm mdmax:mx-1 text-yellow font-medium whitespace-nowrap">Registrasi Kelas</router-link>
        <Chevron direction="right" width="10" height="10" color="#F7931E" />
        <div class="text-sm font-medium text-neutral-500 ellipsis mdmax:ml-1" :class="className ? '' : 'animate-pulse p-2 bg-neutral-400 w-28'">{{ className }}</div>
      </div>
      <h1 class="font-bold text-3xl mdmax:text-2xl pb-5" :class="className ? '' : 'animate-pulse p-3 bg-neutral-400 w-36 mb-5'">{{ className }}</h1>
      <div class="flex items-start gap-6 mdmax:flex-col-reverse">
        <div class="w-7/10 flex flex-col gap-4 mdmax:w-full">
          <div v-if="!isLoadData">
            <div v-for="(document, index) in registrationDocuments" :key="'documents-' + index" class="flex flex-col gap-4 mb-8 mdmax:mb-3">
              <ActivityCard :title="`${document.registrationDocument.name}`" :isActive="isActicityCardActive" :key="`document-${document.registrationDocument.name}-${index}`">
                <template slot="action">
                  <div v-if="document.uploader === 'Admin'">
                    <LinkButton
                      linkType="external"
                      :disabled="viewDocumentAdmin(document) === ''"
                      buttonText="Lihat Dokumen"
                      type="secondary"
                      :size="isMobile ? 'full' : 'regular'"
                      :url="viewDocumentAdmin(document).url"
                    />
                  </div>
                  <div class="flex mdmax:flex-col-reverse items-center gap-4" v-else>
                    <div class="text-sm font-semibold flex items-center gap-2 text-neutral-400 mdmax:mt-2">
                      <CheckMarkCircle color="yellow" v-if="checkDocumentStatus(document) === 'Sudah terverifikasi'" /><span :class="[checkColorStatus(document)]">{{
                        checkDocumentStatus(document)
                      }}</span>
                    </div>
                    <Button
                      v-if="document.status === 'REQUESTED' || document.status === 'REJECTED'"
                      buttonText="Kumpulkan Dokumen"
                      type="secondary"
                      :size="isMobile ? 'full' : 'regular'"
                      @action="showModalRegistration(document)"
                      additionalClass="mdmax:mb-3"
                    />
                    <LinkButton
                      v-else
                      linkType="external"
                      :disabled="viewDocument(document) === '' && document.status !== 'APPROVED'"
                      buttonText="Lihat Dokumen"
                      type="secondary"
                      :size="isMobile ? 'full' : 'regular'"
                      :url="viewDocument(document).url"
                    />
                  </div>
                </template>
                <template slot="content">
                  <div class="text-sm text-neutral-500">
                    {{ descriptionDocument(document.registrationDocument.name).description }}
                  </div>
                  <div class="bg-yellow-100 p-4 mt-3 text-xs font-medium text-neutral-500 rounded-xl" v-if="descriptionDocument(document.registrationDocument.name).provision">
                    <div class="pb-3 text-xs text-neutral-400 font-semibold">KETENTUAN</div>
                    <ol class="list-decimal pl-3 text-neutral-400">
                      <li v-for="(prov, idxProv) in provisionDocument(descriptionDocument(document.registrationDocument.name).provision)" :key="'prov' + idxProv">
                        {{ prov }}
                        <a
                          :href="viewTemplate(document).url"
                          target="_blank"
                          :class="viewTemplate(document) === '' ? 'pointer-events-none' : 'text-yellow'"
                          v-if="descriptionDocument(document.registrationDocument.name).provision === 'specific' && idxProv === 0"
                          >{{ viewTemplate(document, true) === '' ? '-' : viewTemplate(document).name }}</a
                        >
                      </li>
                    </ol>
                  </div>
                </template>
              </ActivityCard>
            </div>
          </div>
          <div v-if="isLoadData" class="flex justify-center items-center">
            <img src="@/assets/images/illustration/search-animated.svg" class="w-3/4 mdmax:w-full" />
          </div>
          <div class="flex justify-center items-center" v-if="!isLoadData && registrationDocuments.length === 0">
            <div class="w-2/5 mdmax:w-5/6">
              <img src="@/assets/images/illustration/empty-content.svg" class="w-full mdmax:mx-auto mt-10 mdmax:mt-5" />
              <div class="text-center mt-5 font-semibold mdmax:text-sm text-base text-neutral-400">Belum ada data administrasi</div>
            </div>
          </div>
        </div>
        <div class="w-3/10 flex flex-col mdmax:w-full" v-if="registrationDocuments.length !== 0 && !isLoadData">
          <template>
            <div class="mb-6">
              <InformationCard title="Progres">
                <template slot="content">
                  <div class="flex justify-between gap-4 items-center">
                    <p class="flex-shrink-0 text-sm font-semibold mdmax:mr-1" :class="progressPercentage || progressPercentage === 0 ? '' : 'animate-pulse bg-neutral-500 w-5'">
                      {{ `${progressPercentage}%` }}
                    </p>
                    <div class="relative bg-neutral-50 w-full h-4 rounded-lg z-0">
                      <div class="absolute left-0 h-4 bg-yellow rounded-lg" :style="{ width: `${progressPercentage}%` }"></div>
                    </div>
                  </div>
                </template>
              </InformationCard>
            </div>
            <div class="mb-6">
              <p class="bg-yellow-100 p-4 text-xs font-medium text-neutral-500 rounded-xl">Pastikan nama Anda sama dengan yang terdaftar di akun prakerja. Anda bisa mengganti nama di menu Profile.</p>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { registrationDocument } from '@/constant'
import { showVueToast } from '@/utils'
export default {
  name: 'administrtaion-page',
  data: () => ({
    registrationDocuments: [],
    isModalRegistration: false,
    isActicityCardActive: false,
    templateTemporaryUrl: null,
    fileDocument: null,
    typeFileDocument: '',
    maxFileDocument: 5000000,
    className: '',
    registrationDocumentId: 0,
    registrationDocumentTitle: '',
    newName: null,
    progressPercentage: '',
    isLoadData: false
  }),
  components: {
    LinkButton: () => import(/* webpackChunkName: "button" */ '@/components/button/LinkButton'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    InformationCard: () => import(/* webpackChunkName: "InformationCard" */ '@/components/prakerja/InformationCard'),
    ActivityCard: () => import(/* webpackChunkName: "ActivityCard" */ '@/components/prakerja/ActivityCard'),
    CheckMarkCircle: () => import(/* webpackChunkName: "icons" */ '@/components/icons/CheckMarkCircle'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/modal/Modal'),
    CrossCircle: () => import(/* webpackChunkName: "icons" */ '@/components/icons/CrossCircle'),
    DocumentText: () => import(/* webpackChunkName: "icons" */ '@/components/icons/DocumentText'),
    Chevron: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Chevron')
  },
  computed: {
    ...mapGetters('common', ['isMobile', 'loading']),
    registrationId() {
      return this.$route.params.registrationId
    },
    fileType() {
      if (this.registrationDocumentTitle === 'Photo') return '.jpg,.png'
      return '.pdf,.jpg'
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading', 'getUploadUrl', 'updateUploadFile']),
    ...mapActions('student', ['getAdministrationDetail', 'uploadRegistrationFile']),
    descriptionDocument(title) {
      const result = registrationDocument.main.filter((doc) => doc.title === title)
      if (result.length === 0) return { description: 'Silakan kumpulkan dokumen yang diperlukan untuk menyelesaikan proses administrasi.' }
      return result[0]
    },
    remove() {
      this.fileDocument = null
    },
    onChange() {
      this.showLoading()
      const file = this.$refs.file.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        if (e.target.result.length > this.maxFileDocument) {
          showVueToast('Ukuran file maksimal 5 MB!', 'error', 2000)
        } else {
          this.newName = file.name
          this.typeFileDocument = file.type
          this.fileDocument = e.target.result
        }
      }
      reader.readAsDataURL(file)
      setTimeout(() => this.hideLoading(), 2500)
    },
    showModalRegistration(doc) {
      this.registrationDocumentId = doc.id
      this.registrationDocumentTitle = doc.registrationDocument.name
      this.isModalRegistration = true
    },
    hideModalRegistration() {
      this.remove()
      this.templateTemporaryUrl = this.newName = null
      this.isModalRegistration = this.isTemplateAvailable = false
    },
    dragover(event) {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('border-yellow')) {
        event.currentTarget.classList.remove('border-gray-300')
        event.currentTarget.classList.add('border-yellow')
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove('border-yellow')
      event.currentTarget.classList.add('border-gray-300')
    },
    drop(event) {
      if (!this.fileDocument) {
        event.preventDefault()
        this.$refs.file.files = event.dataTransfer.files
        this.onChange()

        event.currentTarget.classList.add('border-gray-300')
        event.currentTarget.classList.remove('border-yellow')
      } else {
        this.showLoading()
        showVueToast('Only 1 file can be upload!', 'error', 2000)
        setTimeout(() => this.hideLoading(), 2500)
      }
    },
    provisionDocument(type) {
      if (type === 'file') return registrationDocument.provision.file
      if (type === 'image') return registrationDocument.provision.image
      return registrationDocument.provision.specific
    },
    forGetUploadUrl() {
      this.showLoading()
      this.getUploadUrl({
        params: { contentType: this.typeFileDocument }
      }).then(
        (response) => this.uploadDocument(response),
        () => {
          showVueToast('Request failed, please try again later!', 'error', 2000)
          setTimeout(() => this.hideLoading(), 3500)
        }
      )
    },
    uploadDocument(response) {
      const binary = atob(this.fileDocument.split(',')[1])
      const array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      const blobData = new Blob([new Uint8Array(array)], { type: this.typeFileDocument })
      this.updateUploadFile({
        url: response.uploadUrl,
        data: blobData,
        contentType: this.typeFileDocument
      }).then(
        () => this.uploadRegistrationDocument(response.uploadUrl.split('?')[0]),
        () => {
          showVueToast('Request failed, please try again later!', 'error', 2000)
          setTimeout(() => this.hideLoading(), 3500)
        }
      )
    },
    uploadRegistrationDocument(temporaryUrl) {
      const payloads = {
        temporaryUrl,
        fileName: this.newName
      }
      this.uploadRegistrationFile({ registrationId: this.registrationId, registrationDocumentId: this.registrationDocumentId, payloads }).then(
        () => {
          showVueToast('Upload dokumen berhasil!', 'success', 3000)
          this.hideModalRegistration()
          this.loadData()
        },
        () => {
          this.hideLoading()
          showVueToast('Upload dokumen gagal!', 'error', 2000)
        }
      )
    },
    viewTemplate(doc) {
      if (doc.templateFile) return doc.templateFile
      return ''
    },
    viewDocumentAdmin(doc) {
      if (doc.uploadedFile) return doc.uploadedFile
      return ''
    },
    viewDocument(doc) {
      if (doc.uploadedFile) return doc.uploadedFile
      if (doc.templateFile) return doc.templateFile
      return ''
    },
    loadData() {
      this.isLoadData = true
      this.showLoading()
      this.getAdministrationDetail({ registrationId: this.registrationId }).then(
        (response) => {
          this.className = response.coreClassName
          this.registrationDocuments = response.programName === 'INDODIGI' ? this.pushIndodigiTemplate(response.registrationDocuments) : response.registrationDocuments
          this.progressPercentage = this.checkNumberType(response.progressPercentage)
          this.isActicityCardActive = response.autoExpandActivity
          this.isLoadData = false
          this.hideLoading()
        },
        () => {}
      )
    },
    pushIndodigiTemplate(response) {
      return response.map((res) => {
        if (res?.registrationDocument?.masterDocumentResponse?.code === 'SRKPS') {
          const templateFile = {
            id: '',
            name: 'Template Surat Rekomendasi',
            url: 'https://g2labcms.s3.ap-southeast-1.amazonaws.com/Template+Surat+Rekomendasi+IndoDigi.docx'
          }
          return {
            ...res,
            templateFile
          }
        }

        return res
      })
    },
    checkDocumentStatus(doc) {
      let status = ''
      switch (doc.status) {
        case 'REQUESTED':
          status = 'Belum mengumpulkan'
          break
        case 'APPROVED':
          status = 'Sudah terverifikasi'
          break
        case 'NEED_APPROVAL':
          status = 'Menunggu persetujuan'
          break
        default:
          status = 'Belum sesuai ketentuan'
          break
      }
      return status
    },
    checkColorStatus(doc) {
      if (doc.status === 'APPROVED') return 'text-tosca'
      if (doc.status === 'REJECTED') return 'text-red'
      return ''
    },
    checkNumberType(variable) {
      if (Number.isInteger(variable)) return variable
      return parseFloat(variable.toFixed(2))
    }
  }
}
</script>

<style scoped lang="scss">
.list-note::before {
  content: '•';
  color: #333333;
  display: inline-block;
  width: 1em;
}
.child-activity {
  & > .child-activity-item {
    &:last-child {
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
</style>
